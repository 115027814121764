export const checkoutText = {
  header: `It looks like <span class="nowrap">Quell Fibromyalgia</span> may be right for you!`,
  body: `Your doctor consult will be provided by Beluga Health, a telemedicine provider that serves patients across the country. Your $30 payment goes directly to Beluga Health and covers the cost of your doctor consult. If medically appropriate, treatment may be prescribed to you. The doctor will contact you within the next 24 hours (often times much sooner) via email and text message to complete your consultation.`,
  footer: `In order to verify your identity, please upload a picture of your government-issued photo ID.`,
  payment: `Your doctor consult will be provided by Beluga Health, a telemedicine provider that serves patients across the country. Your $30 payment goes directly to My A+ Health and covers the cost of your doctor consult. If medically appropriate, treatment may be prescribed to you.`,
};

export const introPage = {
  header: `The following questions will help our physicians determine if you're eligible for the Quell Fibromyalgia device.`,
  body: `If we determine you are eligible, you'll pay a $30 consultation fee to Beluga Health for your doctor consult. A prescription will then be automatically sent on your behalf to the online pharmacy, Health Warehouse, for fulfillment.`,
  footer: `Beluga Health is a US licensed telemedicine provider.`,
};

export const paymentSuccess = {
  header: `Thank you! Your information was submitted successfully.`,
  body: `A doctor will now review your intake form. The doctor will contact you within the next 24 hours (often times much sooner) via email and text message to complete your consultation.`,
  footer: `Beluga Health is a US licensed telemedicine provider.`,
};

export const dataSecure = {
  header:
    "Your personal information is securely transmitted via HIPAA compliant transfer to the Beluga Health medical team.",
};
