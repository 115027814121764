/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, Fragment } from "react";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import {
  stateNameToAbbreviationMap,
  statesFullNameList,
} from "../DataFiles/states";

import "../MainForm/MainForm.css";

const FormStep = (props) => {
  const {
    questionnaire,
    currentStep,
    localAnswer,
    lastAnswerArray,
    setLastAnswerArray,
    page,
    lastPage,
    formData,
    setFormData,
    setValidated,
    mostRecent,
    isSub,
  } = props;

  const previousQuestion = isSub ? questionnaire[page - 1] : null;
  const filteredPrevAnswers = isSub
    ? previousQuestion.answers.filter((answer) =>
        formData[`A${page - 1}`].includes(answer.answer)
      )
    : null;
  const myRef = useRef(null);
  const errorRef = useRef(null);

  const [localState, setLocalState] = useState({
    question: currentStep.question,
    answer: localAnswer,
  });
  const [localMultiquestionState, setLocalMultiquestionState] = useState(
    isSub
      ? currentStep.subQuestions
          .filter((subq) =>
            filteredPrevAnswers.some((item) => item.condId === subq.condId)
          )
          .map((subq) => {
            return {
              question: subq.question,
              answer: "",
            };
          })
      : null
  );
  const [errorMessageHeader, setErrorMessageHeader] = useState("");
  const [errorMessageBody, setErrorMessageBody] = useState("");
  const [warningMessage, setWarningMessage] = useState("");

  const cleanQuestion = currentStep.question
    .replaceAll("<b>", "")
    .replaceAll("</b>", "");

  const handleChange = (e) => {
    if (currentStep.standardDemo) {
      switch (currentStep.standardDemo) {
        case "allergies":
        case "selfReportedMeds":
        case "medicalConditions":
        case "sex":
          setLocalState({
            ...localState,
            answer: e.target.value,
          });
          setFormData({
            ...formData,
            [currentStep.standardDemo]: e.target.value,
          });
          break;
        default:
          setLocalState({
            ...localState,
            answer: e.target.value,
          });
          setFormData({
            ...formData,
            [currentStep.standardDemo]: e.target.value,
          });
          break;
      }
    } else if (isSub) {
      const oldArray = [...localMultiquestionState];
      const foundItem = oldArray.find(
        (item) => item.question === e.target.name
      );
      const indexOf = oldArray.indexOf(foundItem);
      foundItem.answer = e.target.value;
      oldArray.splice(indexOf, 1, foundItem);
      setLocalMultiquestionState(() => oldArray);
      return;
    } else {
      setLocalState({
        ...localState,
        answer: e.target.value,
      });
      setFormData({
        ...formData,
        [`Q${page}`]: cleanQuestion,
        [`A${page}`]: e.target.value,
      });
    }
  };

  const handleMultiSelect = (e) => {
    const id = e.currentTarget.id;

    const thisAnswer = currentStep.answers.find(
      (answer) => answer.answer === id
    );
    const allOtherAnswers = currentStep.answers.filter(
      (answer) => answer.answer !== id
    );

    setErrorMessageHeader("");
    setErrorMessageBody("");
    setWarningMessage("");
    const ogAnswers = localState.answer;
    const formDataIndex = ogAnswers && ogAnswers.indexOf(id);
    let newAnswers;
    if (ogAnswers && ogAnswers.includes(id)) {
      ogAnswers.splice(formDataIndex, 1);
      newAnswers = ogAnswers;
    } else if (id === "None of these apply") {
      newAnswers = [id];
    } else {
      const noneIndex = ogAnswers && ogAnswers.indexOf("None of these apply");
      if (noneIndex === 0) {
        ogAnswers.splice(noneIndex, 1);
      }
      newAnswers = ogAnswers ? [...ogAnswers, id] : [id];
    }
    setLocalState({
      ...localState,
      answer: newAnswers,
    });

    if (thisAnswer.warning) {
      setWarningMessage(thisAnswer.warning);
    }
    if (thisAnswer.error) {
      setErrorMessageHeader(thisAnswer.error.header);
      setErrorMessageBody(thisAnswer.error.body);
      return;
    }
    const answerChoicesString = currentStep.answers
      .map((answer) => answer.answer)
      .join("; ");
    if (allOtherAnswers.some((answer) => answer.showConditional)) {
      const smallFormData = formData;
      const regexQ = new RegExp(`Q${page + 1}(_\\d)*$`);
      const regexA = new RegExp(`A${page + 1}(_\\d)*$`);
      const withoutNextPage = Object.keys(smallFormData)
        .filter((key) => !regexQ.test(key) && !regexA.test(key))
        .reduce((obj, key) => {
          return {
            ...obj,
            [key]: smallFormData[key],
          };
        }, {});
      setFormData({
        ...withoutNextPage,
        [`Q${page}`]: `${cleanQuestion} POSSIBLE ANSWERS: ${answerChoicesString}`,
        [`A${page}`]: newAnswers,
      });
    } else {
      setFormData({
        ...formData,
        [`Q${page}`]: `${cleanQuestion} POSSIBLE ANSWERS: ${answerChoicesString}`,
        [`A${page}`]: newAnswers,
      });
    }
    const newFullAnswers = currentStep.answers.filter((answer) =>
      newAnswers.includes(answer.answer)
    );
    setLastAnswerArray([
      ...lastAnswerArray,
      {
        page,
        answer: newFullAnswers,
      },
    ]);
  };

  const handleSubRadioClick = (e) => {
    // e.preventDefault();
    const thisQuestion = currentStep.subQuestions.find(
      (subq) => subq.question === e.target.name
    );
    const thisAnswer = thisQuestion.answers.find(
      (answer) => answer.answer === e.target.value
    );
    const oldArray = [...localMultiquestionState];
    const foundItem = oldArray.find((item) => item.question === e.target.name);
    const indexOf = oldArray.indexOf(foundItem);
    foundItem.answer = e.target.value;
    oldArray.splice(indexOf, 1, foundItem);
    if (thisAnswer && thisAnswer.error) {
      setErrorMessageHeader(thisAnswer.error.header);
      setErrorMessageBody(thisAnswer.error.body);
      setValidated(false);
    } else {
      setErrorMessageHeader("");
      setErrorMessageBody("");
      setLocalMultiquestionState(() => oldArray);
    }
  };

  const handleRadioClick = (e) => {
    // e.preventDefault();
    const thisAnswer = currentStep.answers.find(
      (answer) => answer.answer === e.currentTarget.value
    );
    const allOtherAnswers = currentStep.answers.filter(
      (answer) => answer.answer !== e.currentTarget.value
    );
    if (thisAnswer && thisAnswer.hasWarning) {
      setWarningMessage(thisAnswer.warning);
    } else {
      setWarningMessage("");
    }
    if (thisAnswer && thisAnswer.error) {
      setErrorMessageHeader(thisAnswer.error.header);
      setErrorMessageBody(thisAnswer.error.body);
      setLocalState({
        ...localState,
        answer: e.currentTarget.value,
      });
    } else {
      setErrorMessageHeader("");
      setErrorMessageBody("");
      setLocalState({
        ...localState,
        answer: e.currentTarget.value,
      });
      if (currentStep.standardDemo === "sex") {
        setFormData({
          ...formData,
          sex: e.currentTarget.value,
        });
      } else {
        if (
          !thisAnswer.showConditional &&
          allOtherAnswers.some((answer) => answer.showConditional)
        ) {
          const smallFormData = formData;
          delete smallFormData[`Q${page + 1}`];
          delete smallFormData[`A${page + 1}`];

          setFormData({
            ...smallFormData,
            [`Q${page}`]: cleanQuestion,
            [`A${page}`]: e.currentTarget.value,
          });
        } else {
          setFormData({
            ...formData,
            [`Q${page}`]: cleanQuestion,
            [`A${page}`]: e.currentTarget.value,
          });
        }
      }
      if (page < lastPage) {
        setLastAnswerArray([
          ...lastAnswerArray,
          {
            page,
            answer: thisAnswer,
          },
        ]);
      }
    }
  };

  const handleNoneClick = (e) => {
    e.preventDefault();
    handleChange({
      target: {
        value: "None",
      },
    });
  };

  const answerStyle = () => {
    switch (currentStep.type) {
      case "radio":
        return "radioContainer";
      case "checkBox":
        return "checkBoxContainer";
      case "date":
      case "dob":
        return "datePickerContainer";
      case "select":
        return "selectorContainer";
      default:
        return "";
    }
  };

  const formattedSub = () => {
    const filtered = currentStep.subQuestions.filter((subq) =>
      filteredPrevAnswers.some((item) => item.condId === subq.condId)
    );
    const mapped = filtered.map((subq, idx) => {
      let formattedSubanswer;
      if (subq.type === "freeText") {
        formattedSubanswer = (
          <Fragment key={subq.condId}>
            {subq.showPreviousAnswers && (
              <div className="scrollList">
                {filteredPrevAnswers.map((item, idx) => {
                  return (
                    <Fragment key={`${item.answer}${idx}`}>
                      <li
                        className="formFieldSmall"
                        // key={`${item.answer}${idx}`}
                      >
                        {item.answer}
                      </li>
                    </Fragment>
                  );
                })}
              </div>
            )}
            <textarea
              name={subq.question}
              onChange={handleChange}
              className="formTextBox"
              id={idx}
            />
          </Fragment>
        );
      } else {
        const buttons = subq.answers.map((answer, idx) => {
          const foundState = localMultiquestionState.find(
            (item) => item.question === subq.question
          );
          const buttonStyle =
            foundState.answer === answer.answer
              ? "formRadioLine formRadioLineSelected"
              : "formRadioLine";
          return (
            <Fragment key={`${idx}${answer.answer}`}>
              <button
                className={buttonStyle}
                onClick={handleSubRadioClick}
                value={answer.answer}
                name={subq.question}
              >
                {answer.answer}
              </button>
            </Fragment>
          );
        });
        formattedSubanswer = (
          <>
            {errorMessageHeader && (
              <div ref={errorRef} className="errorMessageHeader">{errorMessageHeader}</div>
            )}
            {errorMessageBody && (
              <div className="messageBody">{errorMessageBody}</div>
            )}
            {buttons}
          </>
        );
      }

      return (
        <div key={idx}>
          <div className="formField">
            {subq.question}
            {subq.subText && (
              <div className="formFieldSmall">{subq.subText}</div>
            )}
          </div>
          <div className={answerStyle()}>{formattedSubanswer}</div>
        </div>
      );
    });
    return mapped;
  };

  const handleDateChange = (date) => {
    const formattedDate = `${date.getDay()}/${
      date.getMonth() + 1
    }/${date.getFullYear()}`;
    setLocalState({
      ...localState,
      answer: formattedDate,
      dateObj: date,
    });
  };

  const handleSelectorChange = (e) => {
    const selectedState = e.target.value;
    setLocalState({
      ...localState,
      answer: selectedState,
    });
  };

  const formattedAnswers = currentStep.answers
    ? currentStep.type === "checkBox"
      ? currentStep.answers.map((answer, idx) => {
          const checkBoxLineClass = localState.answer.includes(answer.answer)
            ? "checkBoxLine checkBoxLineSelected"
            : "checkBoxLine";
          const checkClass = localState.answer.includes(answer.answer)
            ? "checkBox checkBoxSelected"
            : "checkBox";
          return (
            <Fragment key={`${idx}${answer.answer}`}>
              <div
                className="checkBoxLineContainer"
                // key={`${idx}${answer.answer}`}
                onClick={handleMultiSelect}
                id={answer.answer}
              >
                <div className={checkBoxLineClass}>
                  <div className={checkClass}>
                    {localState.answer.includes(answer.answer) && (
                      <div className="checkShape" />
                    )}
                  </div>
                  <div className="checkBoxText">{answer.answer}</div>
                </div>
                {/* {localState.answer.includes(answer.answer) &&
                  answer.hasWarning && (
                    <div className='warningText'>Note: {answer.warning}</div>
                  )} */}
              </div>
            </Fragment>
          );
        })
      : currentStep.answers.map((answer, idx) => {
          switch (typeof answer) {
            case "string":
              switch (answer) {
                case "freeText":
                  return (
                    <Fragment key={`${idx}${answer}`}>
                      <div
                        // key={`${idx}${answer}`}
                      >
                        <textarea
                          name={currentStep.question}
                          onChange={handleChange}
                          className="formTextBox"
                          defaultValue={formData[`A${page}`] || localState.answer}
                        />
                      </div>
                    </Fragment>
                  );
                case "allergies":
                case "medicalConditions":
                case "selfReportedMeds":
                  return (
                    <Fragment key={`${idx}${answer}`}>
                      <div
                        // key={`${idx}${answer}`}
                      >
                        <textarea
                          rows={5}
                          cols={40}
                          name={currentStep.question}
                          onChange={handleChange}
                          className="formTextBox"
                          defaultValue={
                            formData[`${answer}`] || localState.answer
                          }
                        />
                        <button
                          onClick={handleNoneClick}
                          className="orderButton"
                          disabled={localState.answer}
                        >
                          None
                        </button>
                      </div>
                    </Fragment>
                  );
                case "states":
                  return (
                    <Fragment key={`${answer}${idx}`}>
                      <div className="formRadioLine formSelector">
                        <label>
                          <select
                            name="state"
                            id="state-select"
                            onChange={handleSelectorChange}
                            required
                            defaultValue=""
                          >
                            <option value="" key="default-val-0" disabled>
                              Select a State
                            </option>
                            {statesFullNameList.map((name, index) => {
                              return (
                                <Fragment key={`${name}-${index}`}>
                                  <option
                                    value={stateNameToAbbreviationMap[name]}
                                    id={`${name}-${index}`}
                                  >
                                    {name}
                                  </option>
                                </Fragment>
                              );
                            })}
                          </select>
                        </label>
                      </div>
                    </Fragment>

                  );
                case "dob":
                case "date":
                case "covidTestDate":
                  return (
                    <DatePicker
                      className="formRadioLine formDatePicker"
                      placeholderText="Select a date"
                      selected={localState.dateObj ? localState.dateObj : null}
                      dateFormat="MM/dd/yyyy"
                      onChange={handleDateChange}
                      key={idx}
                    />
                  );
                default:
                  const buttonStyle =
                    formData[`A${page}`] === answer
                      ? "formRadioLine formRadioLineSelected"
                      : "formRadioLine";
                  return (
                    <button
                      className={buttonStyle}
                      onClick={handleRadioClick}
                      value={answer}
                      name={currentStep.question}
                      key={`${idx}${answer}`}
                    >
                      {answer}
                    </button>
                  );
              }
            case "object":
            default:
              const buttonStyle =
                localState.answer === answer.answer
                  ? "formRadioLine formRadioLineSelected"
                  : "formRadioLine";
              return (
                <button
                  className={buttonStyle}
                  onClick={handleRadioClick}
                  value={answer.answer}
                  name={currentStep.question}
                  key={`${idx}${answer.answer}`}
                >
                  <div className="formRadioCircle">
                    {localState.answer === answer.answer && (
                      <div className="formRadioInnerCircle" />
                    )}
                  </div>
                  <div className="formRadioText">{answer.answer}</div>
                  {/* {localState.answer.includes(answer.answer) &&
                    answer.hasWarning && (
                      <div className="warningText">{answer.warning}</div>
                    )} */}
                </button>
              );
          }
        })
    : null;

  useEffect(() => {
    if (errorMessageHeader && errorMessageHeader.length) {
      errorRef.current.scrollIntoView({ block: "nearest" });
    }
    if (currentStep.answers) {
      if (currentStep.type === "freeText" && localState.answer.length) {
        if (
          currentStep.answers[0] === "bp" &&
          typeof localState.answer !== "string" &&
          localState.answer.filter((item) => item.length).length < 2
        ) {
          setValidated(false);
        } else {
          setValidated(true);
        }
      } else if (currentStep.type === "list" && localState.answer.length) {
        setValidated(true);
      } else if (currentStep.type === "date" && localState.answer.length) {
        setValidated(true);
      } else if (currentStep.type === "select" && localState.answer.length) {
        setValidated(true);
      } else if (
        currentStep.type === "checkBox" &&
        Object.keys(localState.answer).length &&
        !errorMessageHeader
      ) {
        setValidated(true);
      } else if (
        currentStep.type === "radio" &&
        !!localState.answer.length &&
        !errorMessageHeader
      ) {
        setValidated(true);
      } else {
        setValidated(false);
      }
    }
  }, [localState, errorMessageHeader]);

  useEffect(() => {
    if (errorMessageHeader && errorMessageHeader.length) {
      errorRef.current.scrollIntoView({ block: "nearest" });
    }
    if (localMultiquestionState) {
      if (
        localMultiquestionState.every((item) => item.answer.length) &&
        !errorMessageHeader
      ) {
        let newObj = {};
        localMultiquestionState.forEach((item, idx) => {
          newObj[`Q${page}_${idx}`] = item.question;
          newObj[`A${page}_${idx}`] = item.answer;
        });
        setFormData({
          ...formData,
          ...newObj,
        });
        setValidated(true);
      } else {
        setValidated(false);
      }
    }
  }, [localMultiquestionState, errorMessageHeader]);

  useEffect(() => {
    myRef.current.scrollIntoView();
  }, []);

  const questionBulletList =
    currentStep &&
    currentStep.questionBulletPoints &&
    currentStep.questionBulletPoints.map((i) => {
      return <li key={i}>{i}</li>;
    });

  return (
    <div ref={myRef}>
      {isSub ? (
        <div>{formattedSub()}</div>
      ) : (
        <div className="formQuestionContainer">
          <div className="formField">
            <div className="formFieldQuestion">
              <div dangerouslySetInnerHTML={{ __html: currentStep.question }} />
            </div>
            {questionBulletList && (
              <div className="formFieldQuestionBulletPoints">
                {" "}
                <ul>{questionBulletList}</ul>{" "}
              </div>
            )}
            {currentStep.subText && (
              <div className="formFieldSmall">{currentStep.subText}</div>
            )}
            {errorMessageHeader && (
              <div ref={errorRef} className="errorMessageHeader">
                {errorMessageHeader}
              </div>
            )}

            {warningMessage && (
              <div className="warningText">{warningMessage}</div>
            )}

            {errorMessageBody && (
              <div className="messageBody">
                <div dangerouslySetInnerHTML={{ __html: errorMessageBody }} />
              </div>
            )}
            {currentStep.showPreviousAnswers && (
              <div className="scrollList">
                {mostRecent.answer.map((item, idx) => {
                  return (
                    <Fragment key={`${item.answer}${idx}`}>
                      <li
                        className="formFieldSmall"
                        // key={`${item.answer}${idx}`}
                      >
                        {item.answer}
                      </li>
                    </Fragment>
                  );
                })}
              </div>
            )}
          </div>
          <div className={answerStyle()}>{formattedAnswers}</div>
        </div>
      )}
    </div>
  );
};

export default FormStep;
