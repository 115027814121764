export const heightRange = [
  `3'0"`,
  `3'1"`,
  `3'2"`,
  `3'3"`,
  `3'4"`,
  `3'5"`,
  `3'6"`,
  `3'7"`,
  `3'8"`,
  `3'9"`,
  `3'10"`,
  `3'11"`,
  `4'0"`,
  `4'1"`,
  `4'2"`,
  `4'3"`,
  `4'4"`,
  `4'5"`,
  `4'6"`,
  `4'7"`,
  `4'8"`,
  `4'9"`,
  `4'10"`,
  `4'11"`,
  `5'0"`,
  `5'1"`,
  `5'2"`,
  `5'3"`,
  `5'4"`,
  `5'5"`,
  `5'6"`,
  `5'7"`,
  `5'8"`,
  `5'9"`,
  `5'10"`,
  `5'11"`,
  `6'0"`,
  `6'1"`,
  `6'2"`,
  `6'3"`,
  `6'4"`,
  `6'5"`,
  `6'6"`,
  `6'7"`,
  `6'8"`,
  `6'9"`,
  `6'10"`,
  `6'11"`,
  `7'0"`,
  `7'1"`,
  `7'2"`,
  `7'3"`,
  `7'4"`,
  `7'5"`,
  `7'6"`,
  `7'7"`,
  `7'8"`,
  `7'9"`,
  `7'10"`,
  `7'11"`,
  `8'0"`,
  `8'1"`,
  `8'2"`,
  `8'3"`,
  `8'4"`,
  `8'5"`,
  `8'6"`,
  `8'7"`,
  `8'8"`,
  `8'9"`,
  `8'10"`,
  `8'11"`,
];

export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const dates = () => {
  const datesArray = [];
  for (let i = 1; i < 32; i++) {
    datesArray.push(i);
  }
  return datesArray;
};

export const years = (startYear) => {
  const currentYear = new Date().getFullYear();
  const years = [];
  startYear = startYear || 1930;
  while (startYear <= currentYear) {
    years.push(startYear++);
  }
  return years;
};

export const questionnaire = [
  {
    question: "Please select your sex assigned at birth",
    answers: [
      {
        answer: "Female",
      },
      {
        answer: "Male",
      },
    ],
    type: "radio",
    standardDemo: "sex",
  },
  {
    question:
      "Have you been diagnosed with fibromyalgia?",
    answers: [
      {
        answer: "Yes",
        showConditional: false,
        
      },
      {
        answer: "No",
        showConditional: false,
        error: {
          body: '',
          header: `Quell is indicated for the treatment of fibromyalgia only.`,
        },
      },
    ],
    type: "radio",
    isConditional: false,
  },
  {
    question: "What is your age?",
    answers: [
      {
        answer: "Under 18",
        showConditional: false,
        error: {
          body: '',
          header: `Quell is not indicated for use in patients under the age of 18.`
        }
      },
      {
        answer: "18+",
        showConditional: false
      }
    ],
    type: "radio",
    isConditional: false
  },
  {
    question:
      "Do you have an implanted neurostimulator, cardiac pacemaker, defibrillator or any other implanted metallic or electronic device?",
    answers: [
      {
        answer: "Yes",
        showConditional: true,
        error: {
          body: '',
          header: `Persons with implanted neurostimulator, cardiac pacemaker, defibrillator or any other implanted metallic or electronic device should not use Quell.`,
        },
      },
      {
        answer: "No",
        showConditional: false,
      },
    ],
    type: "radio",
    isConditional: false,
  },
  {
    question:
      "Do you have a metal implant in your upper calf region?",
    answers: [
      {
        answer: "Yes",
        showConditional: true,
        error: {
          body: '',
          header: `Persons with metal implants in the calf should not use Quell.`,
        },
      },
      {
        answer: "No",
        showConditional: false,
      },
    ],
    type: "radio",
    isConditional: false,
  },
  {
    question:
      "Are you pregnant?",
    answers: [
      {
        answer: "Yes",
        showConditional: true,
        error: {
          body: '',
          header: `The safety of electrical stimulation during pregnancy has not been established.`,
        },
      },
      {
        answer: "No",
        showConditional: false,
      },
    ],
    type: "radio",
    isConditional: false,
  },
  {
    question:
      "What are the most noticeable symptoms of your fibromyalgia? Select all that apply.",
    answers: [
      {
        answer:
          "Widespread (multisite) pain or tenderness present for at least 3 months",
        showConditional: false,
      },
      {
        answer:
          "Fatigue, sleep disturbances",
        showConditional: false,
      },
      {
        answer:
          "Depression",
        showConditional: false,
      },
      {
        answer:
          "Headache",
        showConditional: false,
      },
      {
        answer:
          "Abdominal cramps",
        showConditional: false,
      },
      {
        answer: "None of these apply",
        showConditional: false,
      },
    ],
    isConditional: false,
    type: "checkBox",
  },
  {
    question:
      "Rate your level of tenderness to touch from 0 (no tenderness) to 10 (very tender)",
      answers: [
        {
          answer: "0-2",
          showConditional: false,
        },
        {
          answer: "3-6",
          showConditional: true,
        },
        {
          answer: "7-10",
          showConditional: false,
        },
      ],
    isConditional: false,
    type: "radio",
  },
  {
    question:
      "Have you had a physical exam with a healthcare provider in the past 5 years?",
    answers: [
      {
        answer: "Yes, I am in good health",
        showConditional: false,
      },
      {
        answer: "Yes, but they found problems",
        showConditional: true,
      },
      {
        answer: "No",
        showConditional: false,
      },
    ],
    type: "radio",
    isConditional: false,
  },
  {
    question: "Please explain any issues during your last physical exam",
    answers: ["freeText"],
    type: "freeText",
    isConditional: true,
  },
  {
    question:
      "Please list any current medicines, vitamins or dietary supplements you take regularly.",
    subText: "Please include the dosage.",
    answers: ["selfReportedMeds"],
    type: "freeText",
    standardDemo: "selfReportedMeds",
    isConditional: false,
  },
  {
    question: "Please list all known allergies:",
    answers: ["allergies"],
    type: "freeText",
    standardDemo: "allergies",
    isConditional: false,
  },
  {
    question: "Please list any prior medical conditions or serious problems:",
    answers: ["medicalConditions"],
    type: "freeText",
    standardDemo: "medicalConditions",
    isConditional: false,
  },
  {
    question:
      "Is there anything else you want your doctor to know about your condition or health?",
    answers: [
      {
        answer: "Yes",
        showConditional: true,
      },
      {
        answer: "No",
        showConditional: false,
      },
    ],
    type: "radio",
    isConditional: false,
  },
  {
    question:
      "Please enter anything else you want your doctor to know about your condition or health.",
    answers: ["freeText"],
    type: "freeText",
    isConditional: true,
  },
];

